/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */
import axios, { AxiosInstance } from 'axios';
import { apiUrl } from '@/globals';

import useJwtTools from './jwt';
import useCookieTools from './cookie';

function generateHttpAuthClientInstance(): AxiosInstance {
  const { getCookie } = useCookieTools();

  const axiosInstance = axios.create({
    baseURL: apiUrl,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFTOKEN',
  });

  axiosInstance.interceptors.request.use(
    async (config) => {
      const modifiedConfig = config;
      const { isSessionActive } = useJwtTools();

      const token = await isSessionActive(
        true,
        getCookie('access_token_payload'),
        getCookie('refresh_token_payload'),
      );

      if (!token) window.location.replace(`/login/?next=${window.location.pathname}`);
      return modifiedConfig;
    },
    (error) => Promise.reject(error),
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) window.location.replace(`/login/?next=${window.location.pathname}`);
      return Promise.reject(error);
    },
  );
  return axiosInstance;
}

function generateHttpClientInstance(): AxiosInstance {
  const axiosInstance = axios.create({
    withCredentials: true,
    baseURL: apiUrl,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return axiosInstance;
}

const axiosAuthInstance = generateHttpAuthClientInstance();
const axiosInstance = generateHttpClientInstance();

class HTTPWrapper {
  get<T>(url: string, params = {}) {
    return axiosInstance.get<T>(url, params);
  }

  post<T>(url: string, data = {}, config = {}) {
    return axiosInstance.post<T>(url, data, config);
  }

  patch<T>(url: string, data = {}, config = {}) {
    return axiosInstance.patch<T>(url, data, config);
  }

  put<T>(url: string, data = {}, config = {}) {
    return axiosInstance.put<T>(url, data, config);
  }

  delete<T>(url: string, config = {}) {
    return axiosInstance.delete<T>(url, config);
  }
}

class HTTPAWrapper extends HTTPWrapper {
  get<T>(url: string, params = {}) {
    return axiosAuthInstance.get<T>(url, params);
  }

  post<T>(url: string, data = {}, config = {}) {
    return axiosAuthInstance.post<T>(url, data, config);
  }

  patch<T>(url: string, data = {}, config = {}) {
    return axiosAuthInstance.patch<T>(url, data, config);
  }

  put<T>(url: string, data = {}, config = {}) {
    return axiosAuthInstance.put<T>(url, data, config);
  }

  delete<T>(url: string, config = {}) {
    return axiosAuthInstance.delete<T>(url, config);
  }
}

const HTTP = new HTTPWrapper();
const HTTPA = new HTTPAWrapper();

// eslint-disable-next-line import/prefer-default-export
export { HTTP, HTTPA };

import { storeToRefs } from 'pinia';
import { createRouter, createWebHistory } from 'vue-router';

import useUserStore from '../store/modules/user';
import useJwtTools from '../utils/jwt';
import useCookieTools from '../utils/cookie';

const LoginView = () => import('../views/auth/LoginView.vue').catch(() => {
  window.location.reload();
});
const NotificationsView = () => import('../views/notifications/NotificationsView.vue').catch(() => {
  window.location.reload();
});

const HelpView = () => import('../views/help/HelpView.vue').catch(() => {
  window.location.reload();
});

const AnalyticsView = () => import('../views/analytics/AnalyticsView.vue').catch(() => {
  window.location.reload();
});

const routes = [
  { path: '/login/', name: 'Login', component: LoginView },
  { path: '/analytics/', name: 'analytics', component: AnalyticsView },
  { path: '/notifications/', name: 'notifications', component: NotificationsView },
  { path: '/help/', name: 'help', component: HelpView },

  { path: '/:catchAll(.*)', redirect: '/notifications/' },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, _from, next) => {
  const publicUrl = [
    '/login/',
  ];
  const authReq = !publicUrl.find((url) => to.path.startsWith(url));
  const userStore = useUserStore();
  const { isLogin } = storeToRefs(userStore);
  const { isSessionActive } = useJwtTools();
  const { getCookie } = useCookieTools();

  if (!authReq) {
    isLogin.value = false;
    next();
  }
  if (authReq) {
    const isSession = await isSessionActive(isLogin.value, getCookie('access_token_payload'), getCookie('refresh_token_payload'));
    if (isSession) next();
    else {
      isLogin.value = false;
      next(`/login/?next=${to.path}`);
    }
  }
});

export default router;

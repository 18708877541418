import { createApp, App as Application } from 'vue';
import * as Sentry from '@sentry/vue';

import './style.css';
import './styles/markdown.css';

import VueClickAway from 'vue3-click-away';
import PortalVue from 'portal-vue';
import App from './App.vue';
import router from './router/router';
import pinia from './store/store';

const app: Application = createApp(App);

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    app,
    dsn: 'https://ddeb34f1659e99210a9d990375f56213@o230097.ingest.sentry.io/4506258713804800',
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost'],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  });
}

app.use(PortalVue);
app.use(VueClickAway);
app.use(router);
app.use(pinia);
app.mount('#app');

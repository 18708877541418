import { HTTP } from '@/utils/http';

import type {
  JwtType,
} from '@/types/auth';

const useAuthentication = () => {
  const login = async (username: string, password: string) => {
    let response = 'server-error';
    return HTTP.post<string>('/api/auth/token/', { username, password })
      .then(() => 'log-in-success')
      .catch((error: any) => {
        if (error.response != null
        && error.response.status != null
        && error.response.status === 401) {
          response = 'invalid-credentials';
        }
        return response;
      });
  };

  const getNewToken = async () => HTTP.post<JwtType>('/api/auth/token/refresh/', { refresh: 'token' })
    .then(() => 'success')
    .catch(() => null);

  return {
    login,
    getNewToken,
  };
};

export default useAuthentication;

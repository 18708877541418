<script setup lang="ts">
import { onMounted } from 'vue';

onMounted(() => {
});

</script>

<template>
  <router-view />
</template>

<style>
* {
  font-family: Poppins, sans-serif;
}
body {
  color: #617182;
}
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .bg-blur {
    --tw-bg-opacity: 0.6;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
  }
}
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .bg-blur-dark {
    --tw-bg-opacity: 0.6;
    -webkit-backdrop-filter: blur(30px) brightness(70%);
    backdrop-filter: blur(30px) brightness(70%);
  }
}
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .bg-blur-small {
    --tw-bg-opacity: 0.6;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  }
}
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .bg-blur-bright {
    --tw-bg-opacity: 0.6;
    -webkit-backdrop-filter: blur(20px) brightness(120%);
    backdrop-filter: blur(20px) brightness(120%);
  }
}
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .bg-blur-medium-bright {
    --tw-bg-opacity: 0.6;
    -webkit-backdrop-filter: blur(12px) brightness(110%);
    backdrop-filter: blur(12px) brightness(110%);
  }
}
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .bg-blur-medium {
    --tw-bg-opacity: 0.6;
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
  }
}
</style>

import { ref, watch } from 'vue';
import { defineStore } from 'pinia';
import { useRouter, useRoute } from 'vue-router';
import type { UserType } from '../../types/user';

import useAuthentication from '../../services/AuthService';
import useCookieTools from '../../utils/cookie';
import { HTTP as axios } from '../../utils/http';

const { getCookie, deleteCookie } = useCookieTools();

const useUserStore = defineStore('user', () => {
  // Services
  const {
    login,
    getNewToken,
  } = useAuthentication();
  const router = useRouter();
  const route = useRoute();

  const user = ref<UserType | null>(localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!) : null);

  const isLogin = ref<boolean>(!!getCookie('access_token_payload'));


  const accessToken = ref<string | null>(getCookie('access_token_payload'));
  const refreshToken = ref<string | null>(getCookie('refresh_token_payload'));

  const loadingAuth = ref(false);
  const errorAuth = ref('');

  const loginUser = (username: string, password: string) => {
    const cleanUsername = username.replace(/ /g, '_');

    login(cleanUsername, password)
      .then((res) => {
        errorAuth.value = res;
        isLogin.value = {
          'log-in-success': true,
          'invalid-credentials': false,
          'server-error': false,
        }[res] || false;
      });
  };

  const logoutUser = () => {
    isLogin.value = false;
    router.push(`/login/?next=${window.location.pathname}`);
  };

  const updateToken = () => getNewToken()
    .then((response) => {
      if (response) {
        accessToken.value = response;
        isLogin.value = true;
        return accessToken.value;
      }
      isLogin.value = false;
      return null;
    });

  watch(isLogin, (value) => {
    if (value) {
      accessToken.value = getCookie('access_token_payload');
      refreshToken.value = getCookie('refresh_token_payload');
      if (route.query.next && route.query.next !== '/login/') router.push(route.query.next as string);
      else router.push('/notifications/');
    } else {
      accessToken.value = null;
      refreshToken.value = null;

      deleteCookie('access_token_payload');
      deleteCookie('refresh_token_payload');
      localStorage.removeItem('user_role');
      localStorage.removeItem('user');
    }
  });

  return {
    user,
    isLogin,
    errorAuth,
    loadingAuth,
    accessToken,
    refreshToken,
    loginUser,
    logoutUser,
    updateToken,
  };
});

export default useUserStore;

const GLOBAL = () => ({
  var1: '',
  apiUrl: (window.location.origin.includes('localhost') ? 'http://localhost:8000' : window.location.origin),
  apiHost: (window.location.host.includes('localhost') ? 'localhost:8000' : window.location.host),
});

const { apiUrl, apiHost } = GLOBAL();

export { apiUrl, apiHost };
export default GLOBAL;

const useCookieTools = () => {
  const cleanString = (str: string): string => {
    let tmp = '';
    let skip = false;

    for (let i = 0; i < str.length; i += 1) {
      if (str[i] !== ' ') {
        tmp += str[i];
        skip = true;
      } else if (skip) tmp += str[i];
    }
    return tmp;
  };
  const getCookie = (name: string) => {
    const cookies = document.cookie.split(';');
    const cookie = cookies.find((c) => {
      const tmp: string = cleanString(c);
      return tmp.startsWith(`${name}=`);
    });

    if (cookie) return cookie.split('=')[1];
    return null;
  };

  const deleteCookie = (name: string): boolean => {
    const isExisting: string | null = getCookie(name);

    if (isExisting) {
      document.cookie = `${name}=;domain=${window.location.hostname};path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
    }
    return true;
  };

  return { getCookie, deleteCookie };
};

export default useCookieTools;

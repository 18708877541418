import mem from 'mem';

import useAuthentication from '../services/AuthService';

const useJwtTools = () => {
  const decodeJwt = (payload: string) => {
    try {
      const json: string = decodeURIComponent(window.atob(payload));
      return JSON.parse(json);
    } catch (e) {
      return null;
    }
  };

  const isTokenValid = (token: string) => {
    const payload = decodeJwt(token);
    const now = new Date().getTime() / 1000;

    if (!payload || !payload.exp) return false;
    return now < payload.exp;
  };

  const memorizedUpdateToken = mem(async () => {
    const { getNewToken } = useAuthentication();
    const token = await getNewToken();
    return token;
  }, { maxAge: 5000 });

  const isSessionActive = async (isLogin: boolean, access: string | null, refresh: string | null) => {
    if (access && refresh) {
      if (isLogin && isTokenValid(access)) return access;
      if (isTokenValid(refresh)) {
        const token = await memorizedUpdateToken();
        if (token) return token;
      }
    }
    return null;
  };

  return {
    decodeJwt,
    isTokenValid,
    isSessionActive,
  };
};

export default useJwtTools;
